import * as React from 'react';

export const TrashPin = () => (
  <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.185 39a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3h-6.65l-6.35 8-6.32-8h-6.68Z"
      fill="#B54608"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.185 8a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2h-7l-6 7-6-7h-7a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h26Z"
      fill="#F17120"
    />
    <path
      d="M18.15 11.274h-1.015v-.727a.254.254 0 0 0-.053-.188.29.29 0 0 0-.192-.048h-.411a.29.29 0 0 0-.192.048.254.254 0 0 0-.053.188v3.588a.252.252 0 0 0 .053.188.286.286 0 0 0 .192.048h.411a.286.286 0 0 0 .193-.048.252.252 0 0 0 .052-.188V12.91h-.437v-.709h1.453v2.04c0 .577-.318.866-.954.866h-1.025c-.636 0-.954-.29-.953-.867v-3.798c0-.577.318-.866.953-.866h1.025c.635 0 .953.289.953.866v.832ZM21.17 15.098l-.21-1.146H19.75l-.192 1.146h-1.006l1.111-5.522h1.357l1.164 5.522H21.17Zm-1.287-1.908h.936l-.481-2.643-.455 2.643ZM25.237 9.576v.77h-.97v4.752H23.26v-4.752h-.963v-.77h2.94ZM26.717 14.328h1.68v.77H25.7V9.576h2.626v.77h-1.61v1.531h1.356v.78h-1.356v1.67Z"
      fill="#F17120"
    />
    <path
      d="m19.361 17.344.691-1.72a.993.993 0 0 1 .922-.624h2.422c.405 0 .77.247.921.623l.691 1.72M19.361 20.625v6.563M22.186 20.625v6.563M25.009 20.625v6.563M14.185 17.813h16M16.538 17.813v11.194c0 .548.445.993.993.993h9.308a.993.993 0 0 0 .993-.993V17.812"
      stroke="#fff"
      strokeWidth={1.49}
    />
  </svg>
);
