import * as React from 'react';

export const LaundryPin = () => (
  <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.185 39a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3h-6.65l-6.35 8-6.32-8h-6.68Z"
      fill="#B54608"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.185 8a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2h-7l-6 7-6-7h-7a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h26Z"
      fill="#F17120"
    />
    <path
      d="M18.15 11.274h-1.015v-.727a.254.254 0 0 0-.053-.188.29.29 0 0 0-.192-.048h-.411a.29.29 0 0 0-.192.048.254.254 0 0 0-.053.188v3.588a.252.252 0 0 0 .053.188.286.286 0 0 0 .192.048h.411a.286.286 0 0 0 .193-.048.252.252 0 0 0 .052-.188V12.91h-.437v-.709h1.453v2.04c0 .577-.318.866-.954.866h-1.025c-.636 0-.954-.29-.953-.867v-3.798c0-.577.318-.866.953-.866h1.025c.635 0 .953.289.953.866v.832ZM21.17 15.098l-.21-1.146H19.75l-.192 1.146h-1.006l1.111-5.522h1.357l1.164 5.522H21.17Zm-1.287-1.908h.936l-.481-2.643-.455 2.643ZM26.717 14.328h1.68v.77H25.7V9.576h2.626v.77h-1.61v1.531h1.356v.78h-1.356v1.67Z"
      fill="#F17120"
    />
    <path
      d="M27.525 24.167h-1.699a.114.114 0 0 0-.113.13l.348 2.594m1.464-2.724.366 5.448m-.366-5.448h1.83m-1.464 5.448h-1.364a.114.114 0 0 1-.113-.1l-.353-2.624m1.83 2.724h1.098m.366-5.448h1.7c.069 0 .122.06.113.13l-.349 2.594m-1.464-2.724-.366 5.448m0 0h1.364a.114.114 0 0 0 .113-.1l.353-2.624m0 0h-4.758"
      stroke="#fff"
      strokeWidth={0.727}
    />
    <rect x={13.185} y={15} width={11.273} height={15} rx={0.519} fill="#fff" />
    <rect x={13.99} y={15.833} width={4.831} height={1.667} rx={0.26} fill="#F17120" />
    <rect x={22.043} y={15.833} width={1.61} height={1.667} rx={0.26} fill="#F17120" />
    <ellipse cx={18.822} cy={24.167} rx={4.026} ry={4.167} fill="#F17120" />
    <path
      d="M18.822 24.167c-.943-.977-2.651-.792-1.708-1.768a2.359 2.359 0 0 1 3.416 0c.944.976.944 2.56 0 3.535-.943.977-.765-.79-1.708-1.767Z"
      fill="#fff"
    />
  </svg>
);
