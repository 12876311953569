import * as React from 'react';

export const MarketPin = () => (
  <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.185 39a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3h-6.65l-6.35 8-6.32-8h-6.68Z"
      fill="#B54608"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.185 8a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2h-7l-6 7-6-7h-7a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h26Z"
      fill="#F17120"
    />
    <path
      d="M29.712 28.385c.29-.205.464-.534.473-.888 0-.621-.504-1.125-1.125-1.125H18.935c-1.125 0-1.125-.81-.202-1.125l11.07-3.16a.563.563 0 0 0 .382-.484V16.25H16.09v-1.844a.404.404 0 0 0-.405-.405H13.31a1.125 1.125 0 1 0 0 2.25h.821l2.149 9.346v1.9a2.25 2.25 0 1 0 2.475 1.34 1.12 1.12 0 0 0-.157-.215h7.39a2.25 2.25 0 1 0 4.017.248 2.304 2.304 0 0 0-.293-.484Z"
      fill="#fff"
    />
  </svg>
);
