import * as React from 'react';

export const GaragePin = () => (
  <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.185 39a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3h-6.65l-6.35 8-6.32-8h-6.68Z"
      fill="#B54608"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.185 8a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2h-7l-6 7-6-7h-7a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h26Z"
      fill="#F17120"
    />
    <path
      d="m22.186 17.473-6.883 3.891v1.297h13.765v-1.297l-6.882-3.89ZM15.303 22.661h1.588v9.338h-1.588zM27.48 22.661h1.588v9.338H27.48z"
      fill="#fff"
    />
    <path d="M18.479 24.996h7.412M18.479 28.109h7.412M18.479 31.222h7.412" stroke="#fff" strokeWidth={1.509} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m22.185 14 9 4.642v1.759l-9-4.642-9 4.642v-1.76l9-4.641Z"
      fill="#fff"
    />
  </svg>
);
