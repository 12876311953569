import * as React from 'react';

export const PoolPin = () => (
  <svg viewBox="0 0 45 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.185 7a3 3 0 0 0-3 3v26a3 3 0 0 0 3 3h6.68l6.32 8 6.35-8h6.65a3 3 0 0 0 3-3V10a3 3 0 0 0-3-3h-26Z"
      fill="#B54608"
    />
    <path
      d="M9.185 8a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h7.01l6.001 7 6-7h6.99a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2h-26Z"
      fill="#F17120"
    />
    <path
      d="M12.185 23.331c2.1 2.42 4.694 2.332 7.363 1.794 2.018-.406 4.012-.944 6.037-1.3a8.426 8.426 0 0 1 2.744 0c2.056.325 3.175 1.744 3.856 3.556a24.939 24.939 0 0 0-2.55-1.256c-2.069-.787-4.162-.512-6.25.038-1.706.45-3.394.981-5.125 1.312-2.95.556-5.394-1.094-6.075-4.144Z"
      fill="#fff"
    />
    <path
      d="M26.948 22.306 19.06 23.97l-.037-.069c.862-.469 1.73-.925 2.587-1.406.463-.263.906-.55 1.344-.856a.756.756 0 0 0 .212-1.038 5.419 5.419 0 0 0-.356-.675.969.969 0 0 0-1.319-.456c-1.393.525-2.78 1.069-4.168 1.594a5.76 5.76 0 0 1-.844.268.819.819 0 0 1-1.044-.575.837.837 0 0 1 .431-1.118c.875-.475 1.744-.957 2.644-1.382a30.638 30.638 0 0 1 3.206-1.387c.967-.34 2.039.07 2.532.969.906 1.412 1.768 2.88 2.65 4.33.043.076.062.163.05.138ZM27.186 20.45a2.094 2.094 0 0 1 2.1-2.156 2.137 2.137 0 0 1 2.225 2.156 2.163 2.163 0 0 1-4.325 0Z"
      fill="#fff"
    />
  </svg>
);
