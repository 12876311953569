import React, { SVGProps } from 'react';

export const Compass: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="100%" height="100%" viewBox="0 0 38 42" {...props}>
    <path fill="#000" d="m15 17 4-7 4 7h-8Z" />
    <path fill="#CCC" d="m23 17 7 4-7 4v-8ZM15 25l4 7 4-7h-8ZM15 17l-7 4 7 4v-8Z" />
    <path
      fill="#000"
      d="M20.95 6h-.903l-1.764-3.067V6h-.84V1.682h.946l1.72 3.014V1.682h.84V6ZM19.024 40.376c.213 0 .386-.023.519-.07.252-.09.378-.257.378-.501a.37.37 0 0 0-.188-.331c-.125-.076-.32-.144-.588-.202l-.457-.103c-.45-.102-.76-.212-.932-.331-.291-.2-.437-.51-.437-.935 0-.386.141-.708.422-.963.282-.256.695-.384 1.24-.384.454 0 .842.12 1.163.363.322.24.49.59.506 1.049h-.867c-.015-.26-.129-.445-.34-.554a1.14 1.14 0 0 0-.524-.108c-.233 0-.418.047-.557.14a.448.448 0 0 0-.208.393c0 .154.069.27.205.346.088.05.276.11.563.178l.744.179c.326.078.572.183.738.313.258.204.387.498.387.882 0 .395-.151.723-.454.985-.3.26-.727.39-1.277.39-.563 0-1.005-.129-1.328-.385-.322-.257-.483-.61-.483-1.06h.861c.028.197.081.345.162.442.146.178.397.267.752.267ZM36.463 21.113h-2.098v1.11h2.39V23h-3.272v-4.318h3.167v.764h-2.285v.917h2.098v.75ZM4.658 18.682h.917L4.36 23H3.5l-.522-2.525-.153-.835-.152.835L2.15 23h-.837L.087 18.682h.958l.571 2.472.123.689.126-.674.487-2.487h.952l.512 2.472.132.689.132-.662.577-2.5Z"
    />
  </svg>
);
